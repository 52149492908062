import { Box, BoxProps } from '@mui/material';
import { DetailsDto } from 'dtos/components/DetailsDto';
import React from 'react';

interface DetailsListProps extends BoxProps {
    details: DetailsDto[];
}

export function DetailsList({ details, ...boxProps }: DetailsListProps) {
    return (
        <Box component="ul" padding={0} sx={{ listStyle: 'none', fontSize: 14 }}>
            {details.map((detail) => {
                const { pid, key, value } = detail;
                return (
                    <Box display="flex" justifyContent="space-between" key={pid} component="li">
                        <span>{key}: </span>
                        <strong>{value}</strong>
                    </Box>
                );
            })}
        </Box>
    );
}
