import { useCallback } from 'react';
import { useState } from 'react';

export function useInputFocus() {
    const [touched, setTouched] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);

    const onFocus = useCallback(() => {
        setFocused(true);
    }, []);

    const onBlur = useCallback(() => {
        setFocused(false);
        setTouched(true);
    }, []);

    return { focused, touched, onFocus, onBlur, setFocused };
}
