import { Box, CardProps, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectActivities } from 'store/selectors/activities';
import { Activity } from './Activity';
import { ActivityListItem } from './ActivityListItem';
import { TopPagination } from './Pagination/TopPagination';

interface ActivitiesListProps {
    cardProps?: CardProps;
    debug?: boolean;
    readOnly?: boolean;
    variant?: 'grid' | 'list';
    title?: string;
}

export function ActivitiesList({
    cardProps = {},
    debug = false,
    readOnly = true,
    variant = 'list',
    title = 'Activities in area',
}: ActivitiesListProps) {
    const activities = useSelector(selectActivities);

    const gridProps = useMemo(() => {
        return variant === 'grid'
            ? {
                  xs: 12,
                  sm: 6,
                  md: 4,
              }
            : {
                  xs: 12,
                  sx: { mb: 3 },
              };
    }, [variant]);

    const ListItem = useMemo(() => {
        return variant === 'grid' ? Activity : ActivityListItem;
    }, [variant]);

    if (!activities?.length) {
        return null;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box alignItems="center" display="flex" justifyContent="space-between">
                        <Typography variant="h2" sx={{ flex: 1, fontWeight: 'bold', fontSize: { xs: 20 } }}>
                            {title}
                        </Typography>
                        <TopPagination />
                    </Box>
                </Grid>
                {activities.map((activity) => {
                    return (
                        <Grid {...gridProps} item key={activity.id}>
                            <ListItem activity={activity} {...cardProps} readOnly={readOnly} debug={debug} />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}
