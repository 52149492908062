import React, { Fragment } from "react";
import { CellPluginComponentProps } from "@react-page/editor";
import { RawHtmlPluginData } from "./index";
import { CSSPortal } from "../CSSPortal/CSSPortal";

export function RawHtmlPlugin({
  data,
  nodeId,
}: CellPluginComponentProps<RawHtmlPluginData>) {
  const { html, css, className } = data;
  const cssId = `css-${nodeId}`;
  return (
    <Fragment>
      {!!css && <CSSPortal id={cssId} cssString={css} />}
      <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
    </Fragment>
  );
}
