import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAttachmentsMap } from 'hooks/useAttachmentsMap';
import { Box } from '@mui/material';
import styles from './HotelListItem.module.scss';
import { BasicScrollSnapGallery } from '../../components/LazyLightbox/BasicScrollSnapGallery';
import { LazyEditHouseDialog } from '../../views/Rooms/Houses/LazyEditHouseDialog';
import { getLodgeHref } from './lodgingHelpers';
import { HousingItem } from './types/types';
import { HousingDescription } from 'views/LodgingSingle/Common/HousingDescription';

export function HotelListItem({ hotel }: { hotel: HousingItem }) {
    const { attachments = [], name, description, link, rooms } = hotel;
    const attachmentsMap = useAttachmentsMap();

    const images = useMemo(() => {
        return attachments.map((id) => attachmentsMap[id]).filter(Boolean);
    }, [attachmentsMap, attachments]);

    return (
        <Box component={Link as any} to={getLodgeHref(hotel.slug)} elevation={1} className={styles.houseItem}>
            <Box className={styles.header}>
                <span>{name}</span>
                <LazyEditHouseDialog house={hotel} />
            </Box>
            <BasicScrollSnapGallery
                maxHeight={225}
                disableKeyboardEvents
                disableLightboxGallery
                isMobile
                images={images}
            />
            <HousingDescription className={styles.description} description={description} truncate />
        </Box>
    );
}
