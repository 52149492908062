import React from 'react';
import clsx from 'clsx';
import { PluginNames } from '../types/PluginNames';
import { CellPlugin, CellPluginComponentProps, CellPluginCustomControlsComonent, DataTType } from '@react-page/editor';
import { PluginProvider } from '../PluginProvider/PluginProvider';

interface CreateCellPluginOptions<TData extends DataTType> {
    title?: string;
    version?: number;
    component: React.FC<CellPluginComponentProps<TData>>;
    editor?: React.FC;
    cellClassName?: (data: TData) => string | null;
    cellStyle?: (data: TData) => React.CSSProperties;
}

function DefaultEditor<TProps extends DataTType>(props: CellPluginCustomControlsComonent<TProps>) {
    return <h1>Unkown editor</h1>;
}

export function createCellPlugin<TData extends DataTType = any>(
    name: PluginNames,
    {
        title,
        version = 1,
        component,
        editor,
        cellClassName: getClassName,
        cellStyle: getCellStyle,
        isInlineable,
        allowInlineNeighbours,
        createInitialData,
        createInitialChildren,
    }: CreateCellPluginOptions<TData> & Partial<CellPlugin<TData>>
): CellPlugin<TData> {
    const Editor = editor || DefaultEditor;

    return {
        Renderer: component,
        id: name,
        title,
        version,
        Provider: PluginProvider,
        disableProviderInReadOnly: true,
        cellClassName: (data: TData) => {
            return clsx(name, data.cellClassName);
        },
        cellStyle: (data: TData) => {
            return getCellStyle ? getCellStyle(data) : {};
        },
        controls: {
            type: 'custom',
            Component: Editor as any,
        },
        isInlineable,
        allowInlineNeighbours,
        createInitialData,
        createInitialChildren,
    };
}
