import React from 'react';
import { CircularProgress } from '@mui/material';
import styles from './PageLoader.module.scss';

export function PageLoader() {
    return (
        <div id="page-loading">
            <div className={styles.pageLoader}>
                <div className={styles.loader}>
                    <div className={styles.loadWrapper} />
                    <CircularProgress
                        className={styles.progress}
                        size={64}
                    />
                </div>
            </div>
            <div className={styles.pageContent} />
        </div>
    )
}