import React, { Fragment, ReactNode } from 'react';
import loadable from '@loadable/component';

// This dynamic import will not be processed server-side
const FlipMove = loadable(
    () =>
        import(
            /* webpackChunkName: "FlipMove" */
            'react-flip-move'
        )
);

interface SectionsWrapperProps {
    isAdminUser: boolean;
    children: ReactNode[];
}

export function SectionsWrapper({ isAdminUser = false, children }: SectionsWrapperProps) {
    const WrapperComponent = isAdminUser ? FlipMove : Fragment;

    const onStart = (rEl: any, el: HTMLDivElement) => {
        if (el) {
            el.classList.add('animating');
        }
    };

    const onFinish = (rEl: any, el: HTMLDivElement) => {
        if (el) {
            el.classList.remove('animating');
        }
    };

    const wrapperProps = isAdminUser ? { onStart, onFinish } : {};

    return <WrapperComponent {...wrapperProps}>{children}</WrapperComponent>;
}
