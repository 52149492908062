import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { SiteMetaProps } from './SiteMetaProps';
import { useAttachment } from 'hooks/useAttachment';
import SiteMeta from 'components/SiteMeta/SiteMeta';
import { Box, IconButton } from '@mui/material';

const SettingsIcon = loadable(() => import('@mui/icons-material/Settings'));

export function SiteMetaComponent({ data, readOnly }: SiteMetaProps) {
    const { title, description, imageId } = data;
    const image = useAttachment(imageId);

    const siteMeta = useMemo(() => {
        return (
            <div id="SITE_META" data-upload-id={image?.id}>
                <SiteMeta title={title} description={description} image={image} />
            </div>
        );
    }, [title, description, image]);

    if (readOnly) {
        return siteMeta;
    }
    return (
        <>
            {siteMeta}
            <Box display="flex" gap={2}>
                <h4>SEO Config</h4>
                <IconButton>
                    <SettingsIcon />
                </IconButton>
            </Box>
        </>
    );
}
