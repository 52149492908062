import { Box, BoxProps } from '@mui/material';
import { ContactDetailsDto } from 'dtos/components/ContactDetailsDto';
import React from 'react';
import { renderLabel } from 'utils/renderLabel';

interface ContactDetailsProps extends BoxProps {
    className?: string;
    contactDetails: ContactDetailsDto | null;
}

export function ContactDetails({ contactDetails, ...boxProps }: ContactDetailsProps) {
    if (!contactDetails || !contactDetails?.streetAddress) {
        return null;
    }

    const { streetAddress, city, state, zip, phoneNumber, distance } = contactDetails;

    return (
        <Box {...boxProps}>
            <Box py={1}>
                <b>Address:</b>
                <Box display="flex" flexDirection="column">
                    <span>{streetAddress}</span>
                    <span>
                        {city}, {state} {zip}
                    </span>
                    {distance && (<span>{renderLabel('DISTANCE_TO_VENUE', distance)}</span>)}
                </Box>
            </Box>
            {phoneNumber && (
                <Box py={1} display="flex" flexDirection="column">
                    <b>Phone number:</b>
                    <span>{phoneNumber}</span>
                </Box>
            )}
        </Box>
    );
}
