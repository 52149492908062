import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { PageProvider } from './PageProvider';
import { usePage } from './hooks/usePage';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { ReactPageEditor } from './ReactPageEditor';
import { updateSectionCreator } from './sectionHelpers';
import { SectionsWrapper } from './SectionsWrapper/SectionsWrapper';
import { LazyPageTools } from './tools/PageTools/LazyPageTools';
import { FETCH_STATUS } from 'types/types';
import canUseDOM from 'helpers/canUseDom';
import '@react-page/editor/lib/index.css';
import './ReactPage.scss';
import { PageLoader } from './PageLoader/PageLoader';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';

const getIsUserAdmin = (user: any) => {
    return user?.role?.type === 'admin';
};

type PageDataUpdate = (pageData: any[], section?: any) => any[];

export type UpdatePageDataFn = (callbackFn: PageDataUpdate) => {};

export default function ReactPage() {
    const { ref, page, status, setPage, savePage, saveStatus, setPageData } = usePage();
    const [editMode, setEditMode] = useLocalStorage('editMode', false);
    const user = useLoggedInUser();
    const isAdminUser = getIsUserAdmin(user);
    const pageData = page?.pageData || [];

    const updatePageData = useCallback((index: number) => setPageData(updateSectionCreator(index)), [setPageData]);

    const readOnly = !editMode || !isAdminUser;

    if (status === FETCH_STATUS.ERROR && canUseDOM()) {
        return <Redirect to="/404" />;
    }

    if (!page) {
        return <PageLoader />;
    }

    return (
        <PageProvider
            page={page}
            setPageData={setPageData}
            setPage={setPage}
            savePage={savePage}
            saveStatus={saveStatus}
            isAdminUser={isAdminUser}
        >
            <ErrorBoundary>
                <div ref={ref} id="page">
                    {isAdminUser && <LazyPageTools editMode={editMode} setEditMode={setEditMode} />}
                    <SectionsWrapper isAdminUser={!readOnly}>
                        {pageData.map((data, index) => {
                            return (
                                <ReactPageEditor
                                    key={data.id}
                                    data={data}
                                    onChange={updatePageData(index)}
                                    index={index}
                                    readOnly={readOnly}
                                />
                            );
                        })}
                    </SectionsWrapper>
                </div>
            </ErrorBoundary>
        </PageProvider>
    );
}
