import React from 'react';
import { ActivityDto } from 'dtos/ActivityDto';
import { Box, Card, CardMedia, CardProps, Typography } from '@mui/material';
import { ActivityCategories } from './ActivityCategories';
import { LazyActivityForm } from './LazyActivityFormDialog';

interface ActivityProps extends CardProps {
    activity: ActivityDto;
    debug?: boolean;
    readOnly?: boolean;
}

export function ActivityListItem({ activity, elevation, debug, readOnly = true }: ActivityProps) {
    const { title, images, description, categories } = activity;

    const [image] = images || [];

    return (
        <Card sx={{ backgroundColor: 'transparent', height: '100%' }} elevation={elevation}>
            <Box display="flex" gap={2}>
                {image && (
                    <Box flex={1} position="relative">
                        <CardMedia sx={{ maxHeight: 300 }} component="img" src={image.url} />
                        <ActivityCategories categories={categories} />
                    </Box>
                )}

                <Box padding={2} flex={1} display="flex" flexDirection="column">
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: {
                                xs: 20,
                                md: 22,
                            },
                            fontWeight: 'bold',
                            mb: 2,
                        }}
                    >
                        {title}
                    </Typography>
                    {description && <Typography sx={{ fontSize: { xs: 16 } }}>{description}</Typography>}
                    {!readOnly && (
                        <Box>
                            <LazyActivityForm activity={activity} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Card>
    );
}
