import React from 'react';

interface ErrorBoundaryProps {
    children: any;
}

interface ErrorBoundaryState {
    error: string | null;
    errorInfo: any;
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null, hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        const { state } = this;
        const { children } = this.props;

        if (state.hasError) {
            // Error path
            return (
                <>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {state.error && state.error.toString()}
                        <br />
                        {state.errorInfo.componentStack}
                    </details>
                </>
            );
        }
        // Normally, just render children
        return children;
    }
}
