import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import slate from '@react-page/plugins-slate';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { CardComponent } from './CardComponent';
import { CardData } from './CardProps';
import { CardProps } from '@mui/material';

const CardEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "CardEditor" */
            './CardEditor'
        )
);

export const dataCreator = slate((def) => def);

interface ExtraProps extends CellPlugin<CardData> {
    allowChildren?: boolean;
    Editor: React.FC<CardProps>;
}

export const CardPlugin: ExtraProps = {
    Renderer: CardComponent,
    allowChildren: true,
    Editor: CardEditor,
    id: 'CardPlugin',
    title: 'Card Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    cellClassName: (data: CardData) => {
        return clsx('Card', data.cellClassName);
    },
    cellStyle: (_data: CardData) => {
        return {};
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    createInitialData: () => {
        return {};
    },
    createInitialChildren: () => {
        return [
            [
                {
                    plugin: dataCreator,
                    data: dataCreator.createData(({ plugins }) => ({
                        children: [
                            {
                                plugin: plugins.paragraphs.paragraph,
                                children: ['Title and paragraph'],
                            },
                        ],
                    })),
                },
            ],
        ];
    },
    // createInitialChildren,
};
