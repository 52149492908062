import React, { createContext, memo, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import styles from './PluginProvider.module.scss';
import clsx from 'clsx';

import {
    CellPluginComponentProps,
    DataTType,
    Row,
    useBlurCell,
    useCellProps,
    useFocusCell,
    useFocusedNodeId,
    useNodeChildrenIds,
    useNodeHasChildren,
} from '@react-page/editor';
import { LazyPluginToolbar } from './PluginToolbar/LazyPluginToolbar';
import CustomPopover from '../../components/CustomPopover';
import { POPPER_POSITIONS } from '../../components/CustomPopover/types';
import { useHashToggle } from '../../hooks/useHashToggle';

export const PluginContext = createContext<CellPluginComponentProps<DataTType>>(undefined);

const getPluginName = (pluginName = '') => pluginName.replace('Plugin', '').trim();

const getAncestorCount = (numAncestors: number, numChildren: number) => {
    if (numAncestors < 3 && numChildren > 1) {
        return 1;
    }
    return 0;
};

const shouldShowAlways = (rows: Row[], focusedId: string) => {
    if (!focusedId) {
        return false;
    }
    return rows.some((row) => {
        const { cells } = row;
        if (!cells || !cells.length) {
            return false;
        }
        return cells.some((c) => {
            if (c.id === focusedId) {
                return true;
            }
            const { rows: cellRows } = c;
            if (cellRows && cellRows.length) {
                return shouldShowAlways(cellRows, focusedId);
            }

            return false;
        });
    });
};

export const PluginProvider = memo(function PluginProvider({ children, ...context }: any) {
    const wrapperRef = useRef<HTMLDivElement>();
    const blurCell = useBlurCell();
    const focusCell = useFocusCell(context.nodeId);

    const focusedId = useFocusedNodeId();
    const nodeHasChildren = useNodeHasChildren(context.nodeId);
    const nodeData = useCellProps(context.nodeId, (node, ancestors) => {
        return node.rows;
    });

    const isChildFocused = shouldShowAlways(nodeData, focusedId);

    const counts = useCellProps(context.nodeId, (node, ancestors) => {
        return {
            numChildren: node.rows.length,
            numAncestors: ancestors?.length,
            node,
            ancestors,
        };
    });

    const {
        readOnly,
        pluginConfig,
        data: { rowSx },
        focused,
    } = context;

    const alwaysShow = !readOnly;

    const pluginName = getPluginName(pluginConfig.name || pluginConfig.id || '');

    const providerProps = useMemo(() => {
        if (children) {
            const {
                props: { className, children: cellChildren },
            } = children;
            return { className, children: cellChildren };
        }
    }, [children]);

    const onOverlayClick = () => {
        blurCell(context.nodeId);
    };

    if (!readOnly) {
        return (
            <PluginContext.Provider
                value={{
                    ...context,
                    container: wrapperRef,
                    nodeHasChildren,
                    pluginName,
                    counts,
                    closeEditor: onOverlayClick,
                }}
            >
                <CustomPopover
                    isHoverPopover
                    isOpenAlways={alwaysShow}
                    numAncestors={counts.numAncestors}
                    isFocused={focused}
                    // position={POPPER_POSITIONS.UP_LEFT}
                    // style={{
                    //     top: `${getAncestorCount(counts.numAncestors, counts.numChildren) * -40}px`,
                    //     zIndex: 2000 - counts.numAncestors,
                    // }}
                    // shouldDrawBorder={context.focused}
                    // isOpen={context.focused}
                    // onOverlayClick={onOverlayClick}
                    // mouseLeaveTimeout={250}
                    onClick={() => {
                        focusCell();
                    }}
                    toggle={
                        <Box
                            height="100%"
                            ref={wrapperRef}
                            position="relative"
                            className={clsx(providerProps.className, styles.wrapper)}
                            sx={rowSx}
                            data-node-id={context.nodeId}
                        >
                            {providerProps.children}
                        </Box>
                    }
                >
                    <LazyPluginToolbar />
                </CustomPopover>
            </PluginContext.Provider>
        );
    }

    return (
        <Box
            height="100%"
            position="relative"
            className={clsx(providerProps.className, styles.wrapper)}
            sx={rowSx}
            data-node-id={context.nodeId}
        >
            {providerProps.children}
        </Box>
    );
});
