import { PluginNames } from "../types/PluginNames";
import { CellPlugin } from "@react-page/editor";
import loadable from "@loadable/component";
import { RawHtmlPlugin } from "./RawHtmlPlugin.component";
import { PluginProvider } from "../PluginProvider/PluginProvider";
import clsx from "clsx";
import { withTheme } from "../withTheme";

// This dynamic import will not be processed server-side
const RawHtmlPluginEditor = loadable(
  () =>
    import(
      /* webpackChunkName: "RawHtmlPluginEditor" */
      "./RawHtmlPlugin.editor"
    )
);

// use a type here, not an interface
export type RawHtmlPluginData = {
  html: string;
  css?: string;
  className?: string;
};

const CustomRawHTMLPlugin: CellPlugin<RawHtmlPluginData> = {
  Renderer: RawHtmlPlugin,
  id: PluginNames.RAW_HTML,
  title: "Raw HTML Plugin",
  version: 1,
  Provider: PluginProvider,
  disableProviderInReadOnly: true,
  cellClassName: (data: RawHtmlPluginData) => {
    return clsx("html-cell", data.className);
  },
  // cellStyle: (data: RawHtmlPluginData) => {
  //   return { background: "#eee" };
  // },
  controls: {
    type: "custom",
    Component: withTheme(RawHtmlPluginEditor) as any,
  },
};

export default CustomRawHTMLPlugin;
