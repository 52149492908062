import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { SiteMetaComponent } from './SiteMetaComponent';
import { SiteMetaData } from './SiteMetaProps';

const SiteMetaEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "SiteMetaEditor" */
            './SiteMetaEditor'
        )
);

export const SiteMetaPlugin: CellPlugin<SiteMetaData> = {
    Renderer: SiteMetaComponent,
    id: 'SiteMetaPlugin',
    title: 'SiteMeta Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    cellClassName: (data: SiteMetaData) => {
        return clsx('SiteMeta', data.cellClassName);
    },
    cellStyle: (_data: SiteMetaData) => {
        return {};
    },
    controls: {
        type: 'custom',
        Component: SiteMetaEditor,
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    // createInitialData,
    // createInitialChildren,
};
