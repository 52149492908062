import { CollapsibleCardPlugin } from './CollapsibleCard/CollapsibleCardPlugin';
import spacer from '@react-page/plugins-spacer';
import background, { ModeEnum } from '@react-page/plugins-background';
import divider from '@react-page/plugins-divider';
import CustomRawHTMLPlugin from './RawHtmlPlugin';
import { GalleryPlugin } from './GalleryPlugin/GalleryPlugin';

import '@react-page/plugins-slate/lib/index.css';
import '@react-page/plugins-image/lib/index.css';
import '@react-page/plugins-divider/lib/index.css';
import '@react-page/plugins-spacer/lib/index.css';

import { SlatePlugin } from './SlatePlugin/SlatePlugin';
import { BannerPlugin } from './Banner/BannerPlugin';
import { RowPlugin } from './Row/RowPlugin';
import { ImagePlugin } from './Image/ImagePlugin';
import { CtaListPlugin } from './CtaList/CtaListPlugin';
import { SiteMetaPlugin } from './SiteMeta/SiteMetaPlugin';
import { CardPlugin } from './Card/CardPlugin';
import { ActivitiesPlugin } from './Activities/ActivitiesPlugin';
import { CustomCSSPlugin } from './CustomCSS/CustomCSSPlugin';
import { HotelsPlugin } from './hotels/HotelsPlugin';

export const cellPlugins = [
    SlatePlugin,
    ImagePlugin,
    HotelsPlugin,
    BannerPlugin,
    ActivitiesPlugin,
    CardPlugin,
    CollapsibleCardPlugin,
    RowPlugin,
    SiteMetaPlugin,
    CardPlugin,
    CtaListPlugin,
    GalleryPlugin,
    CustomRawHTMLPlugin,
    CustomCSSPlugin,
    spacer,
    background({
        enabledModes: ModeEnum.COLOR_MODE_FLAG | ModeEnum.IMAGE_MODE_FLAG | ModeEnum.GRADIENT_MODE_FLAG,
    }),
    divider,
];
