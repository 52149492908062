import clsx from 'clsx';
import slate from '@react-page/plugins-slate';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { RowComponent } from './RowComponent';
import { RowData } from './RowProps';

const RowEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "RowEditor" */
            './RowEditor'
        )
);

const dataCreator = slate((def) => def);

export const RowPlugin: any = {
    Renderer: RowComponent,
    id: 'RowPlugin',
    title: 'Row Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    cellClassName: (data: RowData) => {
        return clsx('Row', data.cellClassName);
    },
    cellStyle: (_data: RowData) => {
        return {};
    },
    Editor: RowEditor,
    isInlineable: false,
    allowInlineNeighbours: false,
    allowNeighbour: () => false,

    createInitialChildren: () => {
        return [
            [
                {
                    plugin: dataCreator,
                    data: dataCreator.createData(({ plugins }) => ({
                        children: [
                            {
                                plugin: plugins.paragraphs.paragraph,
                                children: ['Title and paragraph'],
                            },
                        ],
                    })),
                },
            ],
        ] as any;
    },
    // createInitialChildren,
};
