import React from 'react';
import { ActivityDto } from 'dtos/ActivityDto';
import { Box, Card, CardContent, CardHeader, CardMedia, CardProps } from '@mui/material';
import { ActivityCategories } from './ActivityCategories';
import { LazyActivityForm } from './LazyActivityFormDialog';
import { DetailsList } from 'components/DetailsList/DetailsList';

interface ActivityProps extends CardProps {
    activity: ActivityDto;
    debug?: boolean;
    readOnly?: boolean;
}

export function Activity({ activity, elevation, debug, readOnly = true }: ActivityProps) {
    const { title, images, description, categories, details } = activity;

    const [image] = images || [];

    return (
        <Card sx={{ backgroundColor: 'transparent', height: '100%' }} elevation={elevation}>
            <CardHeader
                titleTypographyProps={{
                    sx: {
                        fontSize: {
                            xs: 20,
                            md: 22,
                        },
                    },
                }}
                title={title}
                action={!readOnly ? <LazyActivityForm activity={activity} /> : undefined}
            />
            <Box position="relative">
                {image && <CardMedia sx={{ maxHeight: 200 }} component="img" src={image.url} />}
                <ActivityCategories categories={categories} />
            </Box>

            {description && <CardContent>{description}</CardContent>}
            {details && (
                <CardContent>
                    <DetailsList details={details} />
                </CardContent>
            )}

            {debug && <pre>{JSON.stringify(activity, null, 2)}</pre>}
        </Card>
    );
}
