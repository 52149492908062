import { ReactPageDto } from 'dtos/ReactPageDto';
import { MediaDto } from 'dtos/MediaDto';
import React, { createContext, memo, ReactNode, Dispatch, useMemo, useEffect } from 'react';
import { UpdatePageDataFn } from './hooks/usePage';
import { FETCH_STATUS } from 'types/types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Attachments } from 'store/actions/attachments';
import { useSelector } from 'react-redux';
import { selectAttachments } from 'store/selectors/attachments';
import { Cell, Row } from '@react-page/editor';

interface Context {
    page: ReactPageDto;
    attachments: MediaDto[];
    setPageData: UpdatePageDataFn;
    savePage: () => Promise<void>;
    setPage: (page: Partial<ReactPageDto>) => void;
    saveStatus: FETCH_STATUS;
    isAdminUser: boolean;
}

interface Props {
    isAdminUser: boolean;
    page: ReactPageDto;
    setPageData: UpdatePageDataFn;
    setPage: (page: ReactPageDto, shouldSave?: boolean) => void;
    savePage: () => Promise<void>;
    saveStatus: FETCH_STATUS;
    children: ReactNode | ReactNode[];
}

export const PageContext = createContext<Context>(undefined);

export const PageProvider = memo(function PageProvider({
    page,
    isAdminUser,
    savePage,
    setPage,
    saveStatus,
    setPageData,
    children,
}: Props) {
    const dispatch = useAppDispatch();
    const attachments = useSelector(selectAttachments);

    useEffect(() => {
        if (isAdminUser) {
            dispatch(Attachments.GET_ALL());
        }
    }, [isAdminUser, dispatch]);

    const context = useMemo(
        () => ({
            page,
            isAdminUser,
            attachments,
            setPageData,
            savePage,
            setPage,
            saveStatus,
        }),
        [page, isAdminUser, attachments, setPageData, setPage, savePage, saveStatus]
    );

    return <PageContext.Provider value={context}>{children}</PageContext.Provider>;
});
