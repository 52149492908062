import React from 'react';
import { HousingListProps } from '../types/types';
import { Box } from '@mui/material';
import { HousingListItemDesktop } from './HousingListItemDesktop';

export function HousingListDesktop({ items }: HousingListProps) {
    return (
        <Box display="flex" flexDirection="column" gap={2} height="100%">
            {items.map((item) => {
                return <HousingListItemDesktop key={item.id} item={item} />;
            })}
        </Box>
    );
}
