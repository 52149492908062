import React, { useEffect, useRef } from 'react';

const useOutsideClick = (
    onOutsideClick: () => void,
    refs: React.MutableRefObject<HTMLElement>[] | React.Ref<HTMLDivElement>[],
    //TODO: Why would you ever pass true to this? It's literally the entire point of this hook...
    disableOutsideClick = false // Pass true if you DONT want to trigger onOutsideClick cb
) => {
    const onCloseRef = useRef<() => void>();

    useEffect(() => {
        onCloseRef.current = onOutsideClick;
    }, [onOutsideClick]);

    useEffect(() => {
        const checkForOutsideClick = (e: MouseEvent) => {
            const { target } = e;
            // `current` could be `null` if the DOM node a ref was pointing to had already
            // been destroyed by the time this routine runs, so only test those that exist.
            if (refs.some(({ current }: Record<any, any>) => current?.contains(target as HTMLElement))) {
                return;
            }
            if (!disableOutsideClick) {
                onCloseRef.current();
            }
        };

        document.addEventListener('click', checkForOutsideClick, true);

        return () => document.removeEventListener('click', checkForOutsideClick, true);
    }, [disableOutsideClick]); //TODO: Shouldn't the refs also be a dependency? Wouldn't you want this to re-run if the refs changed?
};

export { useOutsideClick };
