import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import loadable from "@loadable/component";
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { HotelsComponent } from './HotelsComponent';
import { HotelsData, HotelsProps } from './HotelsProps';

const HotelsEditor = loadable(
  () =>
    import(
      /* webpackChunkName: "HotelsEditor" */
      "./HotelsEditor"
    )
);

interface HotelsPluginProps extends CellPlugin<HotelsData> {
    allowChildren?: boolean;
    Editor: React.FC<HotelsProps>;
}

export const HotelsPlugin: HotelsPluginProps = {
    Renderer: HotelsComponent,
    Editor: HotelsEditor,
    id: 'HotelsPlugin',
    title: 'Hotels Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    allowClickInside: true,
    cellClassName: (data: HotelsData) => {
        return clsx('Hotels', data.cellClassName);
    },
    cellStyle: (_data: HotelsData) => {
        return {};
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    // createInitialData,
    // createInitialChildren,
};