import { generateId } from "helpers/generateId";

function duplicateCells(cells: any = []) {
  if (!cells || !cells.length) {
    return [];
  }
  return cells.map((cell) => {
    const { rows = [] } = cell;
    return { ...cell, rows: duplicateRows(rows), id: generateId("c") };
  });
}

function duplicateRows(rows: any[] = []) {
  if (!rows || !rows.length) {
    return [];
  }
  return rows.map((row) => {
    return { ...row, id: generateId("r"), cells: duplicateCells(row.cells) };
  });
}

function duplicateSection(section: any = {}, fullWidth = false) {
  const duplicate = {
    ...section,
    id: generateId("s"),
    rows: duplicateRows(section.rows),
    fullWidth,
  };
  return duplicate;
}

export function duplicateSectionCreator(index: number) {
  return (pageData: any[]) => {
    const newData = [...pageData];
    newData.splice(index, 0, duplicateSection(pageData[index]));
    return newData;
  };
}

export function createSectionCreator(newSection: any) {
  return (pageData: any[]) => {
    const newData = [...pageData];
    newData.splice(pageData.length, 0, newSection);
    return newData;
  };
}

export function removeSectionCreator(index: number) {
  return (pageData: any[]) => {
    const newData = [...pageData];
    newData.splice(index, 1);
    return newData;
  };
}

export function updateSectionCreator(index: number) {
  return (pageData: any[], section: any) => {
    const currentSection = pageData[index];
    const newPageData = [...pageData];
    newPageData[index] = { ...currentSection, ...section };
    return newPageData;
  };
}

export function moveSectionUpCreator(index: number) {
  return (pageData: any[]) => {
    const currentSection = pageData[index];
    if (index) {
      const newPageData = [...pageData];
      newPageData.splice(index, 1);
      newPageData.splice(index - 1, 0, currentSection);
      return newPageData;
    }
    return pageData;
  };
}

export function moveSectionDownCreator(index: number) {
  return (pageData: any[]) => {
    const currentSection = pageData[index];
    if (index < pageData.length - 1) {
      const newPageData = [...pageData];
      newPageData.splice(index, 1);
      newPageData.splice(index + 1, 0, currentSection);
      return newPageData;
    }
    return pageData;
  };
}
