import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './Card.module.scss';
import { CardProps } from './CardProps';

export function CardComponent(props: CardProps & { children: ReactNode }) {
    const { data, children } = props;
    const { title, sxProps = {}, titleStyles = {} } = data;

    return (
        <Box sx={sxProps}>
            <Box sx={titleStyles} className={styles.cardHeader} component="h6">
                {title}
            </Box>
            {children}
        </Box>
    );
}
