import canUseDOM from 'helpers/canUseDom';
import React, { lazy, Suspense } from 'react';

const SectionTools = lazy(() => import('./SectionTools/SectionTools'));

export interface SectionToolsProps {
    index: number;
    section: any;
    onUpdateSection: (data: any) => void;
    containerEl: HTMLDivElement | null;
}

export function LazySectionTools(props: SectionToolsProps) {
    if (!canUseDOM()) {
        return null;
    }
    return (
        <Suspense fallback={null}>
            <SectionTools {...props} />
        </Suspense>
    );
}
