import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { CustomCSSProps } from './CustomCSSProps';
import { createPortal } from 'react-dom';
import canUseDOM from 'helpers/canUseDom';

function createCSSContainer(nodeId: string) {
    const styleEl = document.createElement('style');
    styleEl.setAttribute(`data-css-id`, nodeId);
    document.head.appendChild(styleEl);
    return styleEl;
}

function getCssContainer(id: string) {
    if (!canUseDOM()) {
        return null;
    }
    const element = document.querySelector(`[data-css-id="${id}"]`) || createCSSContainer(id);
    // element.innerHTML = '';
    return element as HTMLStyleElement;
}

export function CustomCSSComponent(props: CustomCSSProps) {
    const containerRef = useRef<HTMLStyleElement | null>(getCssContainer(props.nodeId));
    const [hasEmptied, setHasEmptioed] = useState(false);

    useEffect(() => {
        if (!hasEmptied && containerRef.current) {
            containerRef.current.innerHTML = '';
            setHasEmptioed(true);
        }
    }, [hasEmptied]);

    const {
        data: { customCSS },
        readOnly,
        nodeId,
    } = props;

    const portal = useMemo(() => {
        if (!canUseDOM()) {
            return <style data-css-id={nodeId}>{customCSS}</style>;
        }
        if (hasEmptied) {
            return createPortal(<Fragment>{customCSS}</Fragment>, containerRef.current);
        }
        return null;
    }, [customCSS, hasEmptied, nodeId]);

    if (!readOnly) {
        return (
            <Fragment>
                <b>Custom CSS - {customCSS}</b>
                {portal}
            </Fragment>
        );
    }

    return portal;
}
