import canUseDOM from 'helpers/canUseDom';
import React, { lazy, Suspense } from 'react';

const PageTools = lazy(() => import('./PageTools'));

export interface PageToolsProps {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
}

export function LazyPageTools(props: PageToolsProps) {
    if (!canUseDOM()) {
        return null;
    }
    return (
        <Suspense fallback={null}>
            <PageTools {...props} />
        </Suspense>
    );
}
