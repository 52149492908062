export enum PluginNames {
    BANNER = 'BannerPlugin',
    SITE_META = 'SiteMetaPlugin',
    IMAGE = 'imagePlugin',
    RAW_HTML = 'RawHTML',
    TYPOGRAPHY = 'TYPOGRAPHY',
    GALLERY = 'GalleryPlugins',
    CARD = 'CardPlugin',
    ROW = 'ROW',
    CELL = 'CELL',
}
