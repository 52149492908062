import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { ActivitiesComponent } from './ActivitiesComponent';
import { ActivitiesData } from './ActivitiesProps';

const ActivitiesEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "ActivitiesEditor" */
            './ActivitiesEditor'
        )
);

export const ActivitiesPlugin: CellPlugin<ActivitiesData> = {
    Renderer: ActivitiesComponent,
    id: 'ActivitiesPlugin',
    title: 'Activities Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    cellClassName: (data: ActivitiesData) => {
        return clsx('Activities', data.cellClassName);
    },
    cellStyle: (_data: ActivitiesData) => {
        return {};
    },
    controls: {
        type: 'custom',
        Component: ActivitiesEditor,
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    allowClickInside: true,
    // createInitialData,
    // createInitialChildren,
};
