import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { CtaListComponent } from './CtaListComponent';
import { CtaListData } from './CtaListProps';

const CtaListEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "CtaListEditor" */
            './CtaListEditor'
        )
);

export const CtaListPlugin: CellPlugin<CtaListData> = {
    Renderer: CtaListComponent,
    id: 'CtaListPlugin',
    title: 'CtaList Plugin',
    version: 1,
    Provider: PluginProvider as any,
    disableProviderInReadOnly: true,
    cellClassName: (data: CtaListData) => {
        return clsx('CtaList', data.cellClassName);
    },
    cellStyle: (_data: CtaListData) => {
        return {};
    },
    controls: {
        type: 'custom',
        Component: CtaListEditor,
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    createInitialData() {
        return {
            ctas: [{ label: 'Learn more', href: '#learnMore', variant: 'contained', id: 0 }],
        } as any;
    },
    // createInitialChildren,
};
