import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { CollapsibleCardComponent } from './CollapsibleCardComponent';
import { CollapsibleCardData } from './CollapsibleCardProps';
import { dataCreator } from 'widgets/Card/CardPlugin';

const CollapsibleCardEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "CollapsibleCardEditor" */
            './CollapsibleCardEditor'
        )
);

export const CollapsibleCardPlugin: CellPlugin<CollapsibleCardData> = {
    Renderer: CollapsibleCardComponent,
    id: 'CollapsibleCardPlugin',
    title: 'CollapsibleCard Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    cellClassName: (data: CollapsibleCardData) => {
        return clsx('CollapsibleCard', data.cellClassName);
    },
    cellStyle: (_data: CollapsibleCardData) => {
        return {};
    },
    controls: {
        type: 'custom',
        Component: CollapsibleCardEditor,
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    allowClickInside: true,
    createInitialData: () => {
        return {
            backgroundColor: 'transparent',
            elevation: 0,
            paddingMobile: 2,
            paddingDesktop: 4,
        };
    },
    createInitialChildren: () => {
        return [
            [
                {
                    plugin: dataCreator,
                    data: dataCreator.createData(({ plugins }) => ({
                        children: [
                            {
                                plugin: plugins.paragraphs.paragraph,
                                children: ['Collapsible content'],
                            },
                        ],
                    })),
                },
            ],
        ];
    },
    // createInitialData,
    // createInitialChildren,
};
