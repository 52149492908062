import { CellPluginCustomControlsComonent, DataTType } from '@react-page/editor';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import getTheme from 'theme';

export function withTheme<TProps extends DataTType>(Component: React.FC<TProps>) {
    return class ThemeWrapper extends React.PureComponent {
        render() {
            const { props } = this;

            return (
                <ThemeProvider theme={getTheme('light')}>
                    <Component {...(props as TProps)} />
                </ThemeProvider>
            );
        }
    } as CellPluginCustomControlsComonent<TProps>;
}
