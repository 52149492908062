import React from 'react';
import Chip from '@mui/material/Chip';
import { CategoryDto } from 'dtos/CategoryDto';
import { Box } from '@mui/material';

interface CategoriesProps {
    categories?: CategoryDto[];
    position?: 'static' | 'absolute';
    padding?: number;
}

export function ActivityCategories({ categories, position = 'absolute', padding = 1 }: CategoriesProps) {
    if (!categories?.length) {
        return null;
    }
    return (
        <Box position={position} padding={padding} top={0} left={0} display="flex" flexWrap="wrap" gap={2}>
            {categories.map((category) => {
                const { id, title } = category;
                return (
                    <Chip
                        key={id}
                        color="primary"
                        label={title}
                        sx={{
                            '.MuiChip-label': {
                                fontFamily: 'Helvetica, arial;',
                                textTransform: 'uppercase',
                                fontSize: 12,
                                fontWeight: 'bold',
                            },
                        }}
                    />
                );
            })}
        </Box>
    );
}
