import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectActivitiesPagination } from 'store/selectors/activities';
import { renderLabel } from 'utils/renderLabel';

export function TopPagination() {
    const pagination = useSelector(selectActivitiesPagination);

    const { page, pageSize, pageCount, total } = pagination;

    const startIndex = useMemo(() => {
        if (pageCount === 1) {
            return 1;
        }

        if (page > 1) {
            return page * pageSize;
        }

        return 1;
    }, [page, pageSize, pageCount]);

    const endIndex = useMemo(() => {
        if (!total) {
            return 1;
        }

        if (total < pageSize) {
            return total;
        }

        if ((page + 1) * pageCount > total) {
            return total;
        }

        return (page + 1) * pageCount;
    }, [page, pageSize, pageCount, total]);

    const label = !total
        ? renderLabel('PAGINATION_STRING', 0)
        : renderLabel('PAGINATION_WITH_TOTAL', startIndex, endIndex, total);

    return (
        <div>
            <p dangerouslySetInnerHTML={{ __html: label }} />
        </div>
    );
}
