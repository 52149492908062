import { Box, BoxProps, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useMemo, useRef, useState } from 'react';

interface HousingDescriptionProps extends BoxProps {
    description?: string;
    truncate?: boolean;
    showMoreButton?: boolean;
    showMoreHref?: string;
}

const getDescriptionArray = (description: string) => {
    const pieces = description?.split('\n');
    return pieces.filter(Boolean).map((piece, key) => ({
        text: piece,
        key,
    }));
};

export function HousingDescription({
    description,
    truncate,
    showMoreButton,
    showMoreHref,
    ...boxProps
}: HousingDescriptionProps) {
    const [moreActive, setMoreActive] = useState(false);

    const parts = useMemo(() => {
        if (!description) {
            return [];
        }
        return getDescriptionArray(description);
    }, [description]);

    const descriptionArray = useMemo(() => {
        return truncate && !moreActive ? parts.slice(0, 1) : parts;
    }, [parts, moreActive, truncate]);

    const isTruncated = useRef(parts.length > descriptionArray.length);

    if (!description) {
        return null;
    }

    const toggleMore = () => setMoreActive(!moreActive);

    const renderMoreButton = () => {
        if (!showMoreButton && !showMoreHref) {
            return null;
        }

        if (!isTruncated.current && !showMoreHref) {
            return null;
        }

        if (showMoreHref) {
            return <Link to={showMoreHref}>more ...</Link>;
        }

        const toggleText = moreActive ? 'less...' : ' more...';

        return (
            <Button sx={{ fontSize: 'inherit', px: 1, py: 0, fontStyle: 'italic' }} onClick={toggleMore} variant="text">
                {toggleText}
            </Button>
        );
    };

    return (
        <Box pb={1} {...boxProps}>
            {descriptionArray.map(({ text, key }, idx) => {
                const isLast = idx === descriptionArray.length - 1;
                return (
                    <Box component="p" mt={0} mb={isLast ? 0 : 1.5} key={key}>
                        {text}
                        {isTruncated.current && isLast && renderMoreButton()}
                    </Box>
                );
            })}
        </Box>
    );
}
