import clsx from 'clsx';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { BannerComponent } from './BannerComponent';
import { BannerData } from './BannerProps';

const BannerEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "BannerEditor" */
            './BannerEditor'
        )
);

export const BannerPlugin: any = {
    Renderer: BannerComponent,
    id: 'BannerPlugin',
    title: 'Banner Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    cellClassName: (data: BannerData) => {
        return clsx('Banner', data.cellClassName);
    },
    cellStyle: (_data: BannerData) => {
        return {};
    },
    Editor: BannerEditor,
    isInlineable: false,
    allowInlineNeighbours: false,
    // createInitialData,
    // createInitialChildren,
};
