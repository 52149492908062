import React, { ReactNode } from 'react';
import { RowProps } from './RowProps';
import { Box, Container } from '@mui/material';

export function RowComponent(props: RowProps & { children?: ReactNode | ReactNode[] }) {
    const { children, data } = props;

    const { containerSize = 'xl', className, containerSx, sxProps } = data;

    return (
        <Box sx={sxProps}>
            <Container sx={containerSx} className={className} maxWidth={containerSize}>
                {children}
            </Container>
        </Box>
    );
}
