import clsx from 'clsx';
import loadable from '@loadable/component';
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { ImageComponent } from './ImageComponent';
import { ImageData } from './ImageProps';

const ImageEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "ImageEditor" */
            './ImageEditor'
        )
);

export const ImagePlugin: any = {
    Renderer: ImageComponent,
    id: 'imagePlugin',
    title: 'Image Plugin',
    version: 1,
    Provider: PluginProvider,
    disableProviderInReadOnly: true,
    Editor: ImageEditor,
    cellClassName: (data: ImageData) => {
        return clsx('Image', data.cellClassName);
    },
    cellStyle: (_data: ImageData) => {
        return {};
    },
    isInlineable: false,
    allowInlineNeighbours: false,
    // createInitialData,
    // createInitialChildren,
};
