import { PageContext } from "./../PageProvider";
import { useContext } from "react";

export function usePageContext() {
  const context = useContext(PageContext);

  if (!context) {
    throw new Error("pageContext not setup");
  }

  return context;
}
