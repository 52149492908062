import { useEffect, useCallback, useRef, MutableRefObject, useMemo } from 'react';
import { FETCH_STATUS } from 'types/types';
import { useState } from 'react';
import { ReactPageDto } from 'dtos/ReactPageDto';
import { selectCurrentPage } from 'store/selectors/pages';
import { PageRouteParams } from 'types/types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Pages } from 'store/actions/pages';

const IMAGE_ATTRIBUTE = 'data-upload-id';
const IMAGE_SELECTOR = `[${IMAGE_ATTRIBUTE}]`;

const HOTEL_TYPES = ['HOTEL', 'INN', 'MOTEL'];

const ACTIVITIES = `[data-plugin-type="Activities"]`;
const HOUSES = `[data-housing-type="HOTELS"]`;
const HOTELS = HOTEL_TYPES.map((type) => `[data-housing-type="${type}"]`).join(',');

const SELECTORS = {
    ACTIVITIES,
    HOTELS,
    HOUSES,
};

const getBooleanFields = (pageEl: HTMLDivElement | null) => {
    return {
        hasActivities: !!pageEl?.querySelector(SELECTORS.ACTIVITIES),
        hasHotels: !!pageEl?.querySelector(SELECTORS.HOTELS),
        hasHouses: !!pageEl.querySelector(SELECTORS.HOUSES),
    };
};

function getAttachmentsFromContent(pageEl: HTMLDivElement) {
    let attachments = [];
    const imageElements = pageEl.querySelectorAll(IMAGE_SELECTOR);
    if (imageElements) {
        imageElements.forEach((el) => {
            const uploadId = parseInt(el.getAttribute(IMAGE_ATTRIBUTE), 10);
            if (!isNaN(uploadId) && attachments.indexOf(uploadId) === -1) {
                attachments.push(uploadId);
            }
        });
    }
    return attachments;
}

function createSavePayload(page: ReactPageDto, pageRef: HTMLDivElement | null) {
    const attachmentsFromPage = page?.attachments || [];
    let attachments = attachmentsFromPage.map((attachment) => attachment.id);
    if (pageRef) {
        attachments = getAttachmentsFromContent(pageRef);
    }
    return {
        ...page,
        ...getBooleanFields(pageRef),
        attachments,
        pageData: page.pageData || [],
    } as unknown as ReactPageDto;
}

export type PageDataUpdate = (pageData: any[], section?: any) => any[];

export type UpdatePageDataFn = (callbackFn: PageDataUpdate) => (section?: any) => void;

interface UsePageReturnType {
    ref: MutableRefObject<HTMLDivElement>;
    page: ReactPageDto | undefined;
    setPage: (p: ReactPageDto) => void;
    savePage: () => Promise<void>;
    setPageData: UpdatePageDataFn;
    status: FETCH_STATUS;
    saveStatus: FETCH_STATUS;
}

export function usePage(): UsePageReturnType {
    const ref = useRef<HTMLDivElement>(null);
    const shouldSaveRef = useRef<boolean>(false);
    const { slug = 'homepage' } = useParams<PageRouteParams>();
    const dispatch = useAppDispatch();
    const page = useSelector(selectCurrentPage(slug));
    const [saveStatus, setSaveStatus] = useState<FETCH_STATUS | undefined>(undefined);
    const [status, setStatus] = useState<FETCH_STATUS>(page ? FETCH_STATUS.COMPLETE : FETCH_STATUS.LOADING);

    const savePage = useCallback(() => {
        if (saveStatus === FETCH_STATUS.LOADING) {
            return Promise.reject(new Error('Page is already saving'));
        }
        setSaveStatus(FETCH_STATUS.LOADING);

        return dispatch(Pages.SAVE(createSavePayload(page, ref.current)))
            .then(() => {
                setSaveStatus(FETCH_STATUS.COMPLETE);
                setTimeout(() => {
                    setSaveStatus(undefined);
                }, 1500);
            })
            .catch(() => setSaveStatus(FETCH_STATUS.ERROR));
    }, [page, dispatch, saveStatus]);

    useEffect(() => {
        if (!page) {
            setStatus(FETCH_STATUS.LOADING);
            dispatch(Pages.GET(slug))
                .then(() => {
                    setStatus(FETCH_STATUS.COMPLETE);
                })
                .catch(() => {
                    setStatus(FETCH_STATUS.ERROR);
                });
        } else {
            setStatus(FETCH_STATUS.COMPLETE);
        }
    }, [page, dispatch, slug]);

    useEffect(() => {
        if (shouldSaveRef.current) {
            savePage();
            shouldSaveRef.current = false;
        }
    }, [savePage]);

    const setPage = useCallback(
        (pageDto: Partial<ReactPageDto> = {}) => {
            dispatch(Pages.SET_PAGE({ ...page, ...pageDto }));
        },
        [dispatch, page]
    );

    const setPageData = useCallback(
        (callbackFn: PageDataUpdate) => {
            return (section?: any) => {
                const { pageData = [] } = page;
                return setPage({
                    ...page,
                    pageData: callbackFn(pageData, section),
                });
            };
        },
        [page, setPage]
    );

    return useMemo(
        () => ({
            ref,
            page,
            setPage,
            setPageData,
            savePage,
            saveStatus,
            status,
        }),
        [ref, page, setPage, setPageData, savePage, saveStatus, status]
    );
}
