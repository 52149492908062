import React, {
    useState,
    useRef,
    useEffect,
    ReactElement,
    MutableRefObject,
    ReactNode,
    useMemo,
    cloneElement,
    useCallback,
    MouseEventHandler,
    MouseEvent,
} from 'react';
import { createPopper, Instance } from '@popperjs/core';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { getPortalContainer } from './popperUtils';
import { useOutsideClick } from '../../hooks/useClickOutside';
import useConstructibleRef from '../../hooks/useConstructibleRef';
import clsx from 'clsx';
import './Popover.scss';

const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
};

type ChildrenType = ReactElement & { ref?: MutableRefObject<HTMLElement> };

type PopperComponentProps = {
    toggle: ChildrenType;
    children: ReactNode | ReactNode[];
    isHoverPopover?: boolean;
    isOpenAlways?: boolean;
    onClick?: MouseEventHandler;
    isFocused?: boolean;
    numAncestors?: number;
};

const PopperComponent: React.FC<PopperComponentProps> = ({
    toggle,
    children,
    isHoverPopover,
    isOpenAlways,
    isFocused,
    onClick,
    numAncestors = 0,
}) => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const id = useConstructibleRef(() => uniqueId());
    const [isVisible, setIsVisible] = useState<boolean>(isOpenAlways);
    const [visibleClassName, setVisibleClassName] = useState<string>('');
    const buttonRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const popperInstance = useRef<Instance | null>(null);
    const PORTAL = useConstructibleRef(() => getPortalContainer());
    const [menuOpen, setMenuOpen] = useState<boolean>();

    useOutsideClick(() => setIsVisible(false), [buttonRef, tooltipRef]);

    useEffect(() => {
        const rootEl = document.getElementById('root');

        const handleTransitionEnd = (e: any) => {
            if (e.target === rootEl) {
                popperInstance.current.forceUpdate();
            }
        };

        rootEl?.addEventListener('transitionend', handleTransitionEnd, false);

        return () => {
            rootEl?.removeEventListener('transitionend', handleTransitionEnd, false);
        };
    }, []);

    useEffect(() => {
        if (buttonRef.current && tooltipRef.current) {
            popperInstance.current = createPopper(buttonRef.current, tooltipRef.current, {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, numAncestors * -12],
                        },
                    },
                ],
            });

            return () => {
                popperInstance.current.destroy();
            };
        }
    }, [isVisible]);

    const togglePopper = useCallback((e: MouseEvent<HTMLElement>) => {
        if (isOpenAlways) {
            setIsVisible(true);
        } else {
            setIsVisible((prev) => !prev);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (e.type === 'mouseenter') {
            tooltipRef.current?.classList.add('is-visible');
        } else {
            timeoutRef.current = setTimeout(() => {
                tooltipRef.current?.classList.remove('is-visible');
            }, 250);
        }
    }, []);

    const anchor = useMemo<ChildrenType>(() => {
        if (!toggle) {
            return null;
        }

        const setRef = (anchorEl: HTMLElement) => {
            buttonRef.current = anchorEl as HTMLButtonElement;
            if (toggle.ref) {
                toggle.ref.current = anchorEl;
            }
        };

        return cloneElement(toggle, {
            ...toggle.props,
            onClick: togglePopper,
            onMouseEnter: isHoverPopover && togglePopper,
            onMouseLeave: isHoverPopover && togglePopper,
            className: classNames(toggle.props.className, isVisible && 'is-open'),
            ref: setRef,
        });
    }, [toggle, isHoverPopover, togglePopper, isVisible]);

    const tooltipId = `tooltip-${id}`;

    const onTooltipEnter = (e: MouseEvent<HTMLDivElement>) => {
        togglePopper(e);

        if (e.type === 'mouseenter') {
            buttonRef.current?.classList.add('with-shadows');
        } else {
            buttonRef.current?.classList.remove('with-shadows');
        }
    };

    return (
        <>
            {anchor}
            {(isVisible || isOpenAlways) &&
                createPortal(
                    <div
                        ref={tooltipRef}
                        id={tooltipId}
                        onMouseEnter={onTooltipEnter}
                        onMouseLeave={onTooltipEnter}
                        onClick={onClick}
                        role="tooltip"
                        className={clsx('custom-popover', isHoverPopover && 'hover-popover', {
                            'is-focused': isFocused,
                        })}
                    >
                        <div className="custom-popover-content">{children}</div>
                    </div>,
                    PORTAL
                )}
        </>
    );
};

export default PopperComponent;
