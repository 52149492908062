import clsx from 'clsx';
import { CellPlugin } from '@react-page/editor';
import loadable from "@loadable/component";
import { PluginProvider } from '../PluginProvider/PluginProvider';
import { CustomCSSComponent } from './CustomCSSComponent';
import { CustomCSSData, CustomCSSProps } from './CustomCSSProps';

const CustomCSSEditor = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomCSSEditor" */
      "./CustomCSSEditor"
    )
);

interface CustomCSSPluginProps extends CellPlugin<CustomCSSData> {
    allowChildren?: boolean;
    Editor: React.FC<CustomCSSProps>;
}

export const CustomCSSPlugin: CustomCSSPluginProps = {
  Renderer: CustomCSSComponent,
  Editor: CustomCSSEditor,
  id: 'CustomCSSPlugin',
  title: 'CustomCSS Plugin',
  version: 1,
  Provider: PluginProvider,
  disableProviderInReadOnly: true,
  cellClassName: (data: CustomCSSData) => {
      return clsx("CustomCSS", data.cellClassName);
  },
  cellStyle: (_data: CustomCSSData) => {
      return {};
  },
  isInlineable: false,
  allowInlineNeighbours: false,
  // createInitialData,
  // createInitialChildren,
}