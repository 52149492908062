import clsx from 'clsx';
import NavigatePreviousIcon from '@mui/icons-material/ArrowBackIosNew';
import NavigateNextIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useEffect, useRef, useCallback, useState, ReactNode } from 'react';
import styles from './BasicScrollSnapGallery.module.scss';
import IconButton from '@mui/material/IconButton';
import { MediaDto } from '../../dtos/MediaDto';
import { Box } from '@mui/material';
import { LazyGalleryImage } from './LazyGalleryImage';
import canUseDOM from '../../helpers/canUseDom';
import { LazyLightboxGallery } from './LazyLightboxGallery';
import { useInputFocus } from '../../hooks/useInputFocus';

const calculateWidth = (image: MediaDto, containerHeight = 400, isMobile = true) => {
    const { height, width } = image;
    const calculatedWidth = (containerHeight * width) / height;
    if (canUseDOM() && isMobile) {
        if (calculatedWidth > window.innerWidth) {
            return window.innerWidth;
        }
    }
    return calculatedWidth;
};

const handleScrollTo = (scroller: HTMLDivElement, imageEls: HTMLDivElement[], nextIdx: number) => {
    const element = imageEls[nextIdx];
    if (element) {
        const { scrollLeft } = scroller;
        const { left } = element.getBoundingClientRect();
        scroller.scrollTo({ left: scrollLeft + left, top: 0, behavior: 'smooth' });
    }
};

const handleScrollToMobile = (scroller: HTMLDivElement, multiplier: 1 | -1) => {
    const { scrollLeft, offsetWidth } = scroller;
    const newLeftPos = offsetWidth * multiplier + scrollLeft;

    scroller.scrollTo({ left: newLeftPos, top: 0, behavior: 'smooth' });
};

interface BasicScrollSnapGalleryProps {
    images: MediaDto[];
    currentIndex?: number;
    editToggle?: ReactNode;
    isMobile?: boolean;
    disableKeyboardEvents?: boolean;
    maxHeight?: number;
    disableLightboxGallery?: boolean;
    pagingType?: 'bullet' | 'number';
}

export function BasicScrollSnapGallery({
    disableKeyboardEvents,
    images,
    editToggle,
    isMobile,
    maxHeight,
    disableLightboxGallery,
}: BasicScrollSnapGalleryProps) {
    const { focused, onFocus, onBlur } = useInputFocus();
    const [index, setIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [hideNext, setHideNext] = useState(false);
    const imageRefs = useRef<HTMLDivElement[]>([]);
    const scrollRef = useRef(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const preventScroll = useRef(false);

    const showPaging = images.length > 1;

    const toggleDialog = () => {
        setDialogOpen(!dialogOpen);
    };

    const setImageRef = useCallback((index: number) => {
        return (imageCard: HTMLDivElement) => {
            if (imageCard) {
                imageRefs.current[index] = imageCard;
            }
        };
    }, []);

    const onVisibilityChange = useCallback((entry: any) => {
        if (entry.isIntersecting) {
            const { target } = entry;
            const imgIndex = parseInt(target.getAttribute('data-image-index'));
            if (!isNaN(imgIndex)) {
                setIndex(imgIndex);
                preventScroll.current = true;
            }
        }
    }, []);

    useEffect(() => {
        console.log('onVisibilityChange updated');
    }, [onVisibilityChange]);

    const nextIndex = index < images.length - 1 ? index + 1 : undefined;

    const prevIndex = index > 0 ? index - 1 : undefined;

    const onNextClick = useCallback(
        (e?: any) => {
            e?.stopPropagation?.();
            e?.preventDefault?.();

            if (!nextIndex || hideNext) {
                return;
            }
            if (isMobile) {
                handleScrollToMobile(scrollRef.current, 1);
            } else {
                handleScrollTo(scrollRef.current, imageRefs.current, nextIndex);
            }
        },
        [nextIndex, isMobile, hideNext]
    );

    const onPrevClick = useCallback(
        (e?: any) => {
            e?.stopPropagation?.();
            e?.preventDefault?.();

            if (prevIndex === undefined) {
                return;
            }
            if (isMobile) {
                handleScrollToMobile(scrollRef.current, -1);
            } else {
                handleScrollTo(scrollRef.current, imageRefs.current, prevIndex);
            }
        },
        [prevIndex, isMobile]
    );

    useEffect(() => {
        const scrollEl = scrollRef.current;

        const handleScroll = () => {
            const { scrollLeft, offsetWidth } = scrollEl;
            const currentIndex = Math.floor(scrollLeft / (offsetWidth - 10));
            setIndex(currentIndex);
        };

        scrollEl.addEventListener('scroll', handleScroll, false);

        return () => {
            scrollEl.removeEventListener('scroll', handleScroll, false);
        };
    }, [isMobile]);

    useEffect(() => {
        let target: Window | HTMLDivElement = window;

        if (disableKeyboardEvents) {
            target = wrapperRef.current;
        }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                onPrevClick();
            } else if (e.key === 'ArrowRight') {
                onNextClick();
            }
        };

        target.addEventListener('keyup', handleKeyDown, false);

        return () => {
            target.removeEventListener('keyup', handleKeyDown, false);
        };
    }, [onNextClick, onPrevClick, disableKeyboardEvents]);

    const shouldRenderImages = images.filter(Boolean).length > 0;

    return (
        <div
            ref={wrapperRef}
            tabIndex={0}
            onFocus={onFocus}
            onBlur={onBlur}
            id="paginated_gallery"
            className={styles.gallery2}
        >
            <Box height={{ xs: 300, sm: 375, md: 450 }} display="flex" ref={scrollRef} className={styles.scroller2}>
                {shouldRenderImages &&
                    images.map((image, i) => {
                        return (
                            <Box
                                key={image.id}
                                height="100%"
                                onClick={isMobile && toggleDialog}
                                width={calculateWidth(image, 400, isMobile)}
                                ref={setImageRef(i)}
                                className={clsx(styles.cardBasic, {
                                    [styles.cardBasicMobile]: isMobile,
                                })}
                            >
                                <LazyGalleryImage
                                    image={image}
                                    alt={image.name}
                                    root={scrollRef.current}
                                    data-image-index={i}
                                    onVisibilityChange={!disableKeyboardEvents && onVisibilityChange}
                                    threshold={0.5}
                                />
                            </Box>
                        );
                    })}
            </Box>
            {prevIndex !== undefined && (!isMobile || disableKeyboardEvents) && (
                <IconButton
                    onClick={onPrevClick}
                    className={clsx(styles.btn, styles.prev, {
                        [styles.smallIcon]: disableKeyboardEvents,
                    })}
                >
                    <NavigatePreviousIcon />
                </IconButton>
            )}
            {nextIndex !== undefined && !hideNext && (!isMobile || disableKeyboardEvents) && (
                <IconButton
                    onClick={onNextClick}
                    className={clsx(styles.btn, styles.next, {
                        [styles.smallIcon]: disableKeyboardEvents,
                    })}
                >
                    <NavigateNextIcon />
                </IconButton>
            )}
            {editToggle}
            {isMobile && showPaging && (
                <Box className={styles.paging}>
                    {index + 1} / {images.length}
                </Box>
            )}
            {isMobile && !disableLightboxGallery && (
                <Box className={styles.expandToggle}>
                    <LazyLightboxGallery
                        isMobile={isMobile}
                        images={images}
                        currentIndex={dialogOpen ? 0 : undefined}
                        onClose={toggleDialog}
                    />
                </Box>
            )}
        </div>
    );
}
