import React, { useMemo } from 'react';
import { HotelsProps } from './HotelsProps';
import { Box } from '@mui/material';
import { LazyEditHouseDialog } from '../../views/Rooms/Houses/LazyEditHouseDialog';
import { HouseDto } from '../../dtos/HouseDto';
import { useHotels } from './useHotels';
import { useIsMobile } from '../../hooks/useIsMobile';
import { HousingListMobile } from './HousingListMobile/HousingListMobile';
import { HousingListDesktop } from './HousingListDesktop/HousingListDesktop';
import { HousingItem } from './types/types';

export function HotelsComponent(props: HotelsProps) {
    const {
        data: { housingType },
    } = props;

    const isMobile = useIsMobile();

    const hotels = useHotels(housingType);

    const ListComponent = useMemo(() => {
        return isMobile ? HousingListMobile : HousingListDesktop;
    }, [isMobile]);

    return (
        <Box data-housing-type={housingType || 'HOTEL'}>
            <LazyEditHouseDialog isNew house={{} as HousingItem} />
            <ListComponent items={hotels} />
        </Box>
    );
}
