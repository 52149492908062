import React, { useMemo, useState, useCallback } from 'react';
import { CellPluginComponentProps } from '@react-page/editor';
import { usePageContext } from 'views/ReactPage/hooks/usePageContext';
import { Image } from 'components/Image/Image';
import { GalleryPluginProps } from './GalleryPlugin';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Container } from '@mui/material';
import { LazyLightboxGallery } from 'components/LazyLightbox/LazyLightboxGallery';
import styles from './GalleryPlugin.module.scss';

export const useImageData = (imageIds: number[] = []) => {
    const { attachments = [] } = usePageContext();

    const images = useMemo(() => {
        return imageIds.map((id) => attachments.find((attachment) => attachment.id === id)).filter(Boolean);
    }, [imageIds, attachments]);

    return images;
};

export function GalleryPluginComponent({ data }: CellPluginComponentProps<GalleryPluginProps>) {
    const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
    const { imageIds, columnGrid, container, maxWidth } = data;

    const images = useImageData(imageIds);

    const onImageClick = useCallback(
        (index: number) => () => {
            setCurrentIndex(index);
        },
        []
    );

    const onClose = useCallback(() => {
        setCurrentIndex(undefined);
    }, []);

    if (!images.length) {
        return <p>Gallery does not have any images yet</p>;
    }

    const gallery = (
        <Box
            width="100%"
            sx={{
                transition: 'all .5s ease-in-out',
                columnFill: 'initial',
                columnCount: columnGrid,
                columnGap: 0,
            }}
        >
            {images.map((image, idx) => {
                return (
                    <Box
                        role="button"
                        onClick={onImageClick(idx)}
                        className={styles.imgColumn}
                        key={image.id}
                        my={{ xs: 1, md: 1.5 }}
                        mx={{ xs: 1, md: 1.5 }}
                        display="inline-block"
                        sx={{ verticalAlign: 'top' }}
                    >
                        <div className={styles.imgOverlay}>
                            <ZoomInIcon sx={{ color: '#fff', fontSize: 48 }} />
                        </div>
                        <Image {...image} key={image.id} elevated rounded />
                    </Box>
                );
            })}
            <LazyLightboxGallery images={images} currentIndex={currentIndex} onClose={onClose} />
        </Box>
    );

    return container ? <Container maxWidth={maxWidth}>{gallery}</Container> : gallery;
}
