import React, { ForwardedRef, forwardRef, useCallback, useRef } from 'react';
import Editor from '@react-page/editor';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { cellPlugins } from 'widgets/cellPlugins';
import clsx from 'clsx';
import { LazySectionTools } from './tools/LazySectionTools';
import { useTheme } from '@mui/material';

interface ReactPageEditorProps {
    data: any;
    onChange: (data: any) => void;
    readOnly: boolean;
    index: number;
}

const EditableEditor: any = Editor;

const getSpacing = (rowSpacing?: number | string) => {
    return rowSpacing ? `row-spacing-${rowSpacing}` : undefined;
};

export const ReactPageEditor = forwardRef(function ReactPageEditor(
    { data, onChange, readOnly, index }: ReactPageEditorProps,
    ref?: ForwardedRef<any>
) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const theme = useRef(useTheme());

    const {
        id,
        className,
        containerSize = 'xl',
        containerClassName,
        contentClassName,
        containerSx,
        rowSx,
        rowSpacing = 2,
    } = data;

    const setRef = useCallback(
        (container: HTMLDivElement | null) => {
            if (typeof ref === 'function') {
                ref(container);
            }
            containerRef.current = container;
        },
        [ref]
    );

    const editor = (
        <div className={contentClassName}>
            <EditableEditor
                uiTheme={theme.current}
                cellPlugins={cellPlugins}
                value={data}
                onChange={onChange}
                readOnly={readOnly}
                hideEditorSidebar
            />
        </div>
    );

    const renderMarkup = () => {
        return containerSize ? (
            <Container
                sx={containerSx || {}}
                className={clsx('container', containerClassName)}
                maxWidth={containerSize}
            >
                {editor}
            </Container>
        ) : (
            editor
        );
    };

    return (
        <Box
            component="section"
            sx={rowSx}
            className={clsx('section', getSpacing(rowSpacing), { editMode: !readOnly }, className)}
            ref={setRef}
            id={id}
        >
            {renderMarkup()}
            {!readOnly && (
                <LazySectionTools
                    index={index}
                    containerEl={containerRef.current}
                    onUpdateSection={onChange}
                    section={data}
                />
            )}
        </Box>
    );
});
