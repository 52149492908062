import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { getState } from '../../store/selectors/state';
import { HouseDto } from "dtos/HouseDto";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { LodgingAPI } from "api/LodgingAPI";

const getHotels = createSelector(getState, (state) => {
    const { hotels = [] } = state;
    return hotels.filter((h) => h.type !== 'HOUSE');
});

const getHouses = createSelector(getState, (state) => {
    const { hotels = [] } = state;
    return hotels.filter((h) => h.type === 'HOUSE');
});


export function useHotels(type: HouseDto['type']) {
	const isHouses = type === 'HOUSE';
	const hotels = useSelector(isHouses ? getHouses : getHotels);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!hotels.length) {
			dispatch(LodgingAPI.getAll(type));
		}
	}, [hotels?.length, type, dispatch]);

	return hotels;
}