import clsx from 'clsx';
import { Card, CardHeader, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import React, { useState, MouseEvent } from 'react';
import styles from '../Card/Card.module.scss';
import { CollapsibleCardProps } from './CollapsibleCardProps';
import { stopPropagation } from 'utils/stopPropagation';

export function CollapsibleCardComponent(props: CollapsibleCardProps) {
    const { data, nodeId, children } = props;
    const {
        title,
        alignTitleCenter,
        elevation = 0,
        paddingMobile = 2,
        paddingDesktop = 4,
        backgroundColor = 'transparent',
        borderRadius = 2,
        color,
        defaultOpen,
        sxProps = {},
    } = data;

    const [open, setOpen] = useState<boolean>(defaultOpen || false);

    const cardElevation = !open ? 0 : 4;

    const toggleOpen = (e: MouseEvent<HTMLElement>) => {
        stopPropagation(e);
        setOpen(!open);
    };

    return (
        <Card
            elevation={cardElevation}
            id={nodeId}
            className={clsx(styles.card, styles.outlined, {
                [styles.open]: open,
            })}
            sx={{
                'height': '100%',
                backgroundColor,
                borderRadius,
                'padding': {
                    xs: paddingMobile,
                    md: paddingDesktop,
                },
                '*': {
                    color,
                },
                ...sxProps,
            }}
        >
            <CardHeader
                onClick={toggleOpen}
                className={clsx(styles.cardHeader)}
                titleTypographyProps={{ variant: 'h6' }}
                action={!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                sx={{ textAlign: alignTitleCenter ? 'center' : undefined }}
                title={title}
            />
            <Collapse in={open}>{children}</Collapse>
        </Card>
    );
}
