import { GridDto } from "./../../dtos/components/GridDto";
import React from "react";
import { createCellPlugin } from "../utils/createCellPlugin";
import { PluginNames } from "../types/PluginNames";
import { DataTType } from "@react-page/editor";
import loadable from "@loadable/component";
import { GalleryPluginComponent } from "./GalleryPlugin.component";

// This dynamic import will not be processed server-side
const GalleryPluginEditor = loadable(
  () =>
    import(
      /* webpackChunkName: "GalleryPluginEditor" */
      "./GalleryPlugin.editor"
    )
);

export interface GalleryPluginProps extends DataTType {
  imageIds: number[];
  className?: string;
  container: boolean;
  maxWidth?: "xl" | "lg" | "xs" | "sm" | "md";
  columnGrid: Omit<GridDto, "id">;
  itemGridProps?: { [key: number]: Omit<GridDto, "id"> | undefined };
}

export const GalleryPlugin = createCellPlugin<GalleryPluginProps>(
  PluginNames.GALLERY,
  {
    title: "Gallery Plugin",
    component: GalleryPluginComponent,
    editor: GalleryPluginEditor,
    cellClassName: (data: GalleryPluginProps) => {
      return data.className;
    },
    createInitialData: (cell) => {
      return {
        maxWidth: "xl",
        container: false,
        imageIds: [],
        columnGrid: { xs: 2, sm: 3, md: 4 },
      };
    },
  }
);
