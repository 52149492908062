import React from 'react';
import { BannerLayouts, BannerProps } from './BannerProps';
import { Box, Container } from '@mui/material';
import { useAttachment } from 'hooks/useAttachment';
import { Image } from 'components/Image/Image';
import styles from './Banner.module.scss';
import { HEADER_BG } from 'theme';
import { CtaListMarkup } from 'widgets/CtaList/CtaListComponent';
import { CtaButtonProps } from 'widgets/CtaList/CtaListProps';

export function BannerComponent({ data }: BannerProps) {
    const {
        title,
        ctas = [],
        description,
        imageId,
        imageOpacity = 1,
        backgroundColor = HEADER_BG,
        sxProps = {},
        minHeightDesktop,
        minHeightMobile,
        containerSize,
        containerSx = {},
        titleSx = {},
        descriptionSx = {},
        layout,
    } = data;

    const backgroundImage = useAttachment(imageId);

    const renderContent = () => {
        switch (layout) {
            case BannerLayouts.CTA_RIGHT: {
                return (
                    <Box
                        gap={2}
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap="wrap"
                        display="flex"
                        sx={containerSx}
                    >
                        <Box className={styles.leftPanel}>
                            <Box sx={titleSx} component="h1" dangerouslySetInnerHTML={{ __html: title }} />
                            {description && (
                                <Box
                                    component="p"
                                    dangerouslySetInnerHTML={{ __html: description }}
                                    sx={descriptionSx}
                                />
                            )}
                        </Box>

                        <Box>
                            <CtaListMarkup isBanner ctas={ctas as CtaButtonProps[]} gap={2} justifyContent="center" />
                        </Box>
                    </Box>
                );
            }

            default: {
                return (
                    <>
                        {backgroundImage && (
                            <div style={{ opacity: imageOpacity }} className={styles.bgImage}>
                                <Image {...backgroundImage} useOriginal />
                            </div>
                        )}

                        <Box position="relative" zIndex={1} textAlign="center" sx={!!containerSize && containerSx}>
                            {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
                            {description && (
                                <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
                            )}
                            {ctas?.length > 0 && (
                                <Box pt={1.5} pb={1.5}>
                                    <CtaListMarkup
                                        isBanner
                                        ctas={ctas as CtaButtonProps[]}
                                        gap={2}
                                        justifyContent="center"
                                    />
                                </Box>
                            )}
                        </Box>
                    </>
                );
            }
        }
    };

    return (
        <Box
            className="banner-widget"
            sx={{
                backgroundColor: 'var(--banner-bg)',
                color: 'var(--banner-color)',
                borderBottom: '1px solid #ddd',
                pt: { xs: 1, sm: 0 },
                pb: { xs: 1 },
                minHeight: {
                    xs: minHeightMobile,
                    md: minHeightDesktop,
                },
                ...sxProps,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
        >
            {containerSize ? <Container maxWidth={containerSize}>{renderContent()}</Container> : renderContent()}
        </Box>
    );
}
