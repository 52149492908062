import React from 'react';
import { ImageProps } from './ImageProps';
import { Image } from 'atoms/Image/Image';
import { useAttachment } from 'hooks/useAttachment';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const DEFAULT_CAPTION_SX = {
    bottom: 0,
    padding: 2,
    left: 0,
    right: 0,
    background: 'var(--blue-300-opacity)',
    color: '#fff',
    textAlign: 'center',
};

function getCaptionSx(rounded: boolean) {
    return !rounded ? DEFAULT_CAPTION_SX : { ...DEFAULT_CAPTION_SX, borderRadius: '0 0 8px 8px' };
}

export function ImageComponent(props: ImageProps) {
    const {
        data: { imageId, className, elevated, rounded, maxHeight, caption, sxProps = {} },
    } = props;

    const image = useAttachment(imageId);

    return (
        <Box sx={{ maxHeight, position: 'relative', ...sxProps }}>
            <Image image={image} rounded={rounded} elevated={elevated} className={className} />
            {caption && (
                <Box position="absolute" sx={getCaptionSx(rounded)}>
                    <div dangerouslySetInnerHTML={{ __html: caption }} />
                </Box>
            )}
        </Box>
    );
}
