import slate from '@react-page/plugins-slate';

export const SlatePlugin = slate((def) => {
    const plugins = def.plugins;
    const headings = plugins.headings;

    return {
        ...def,
        hideInMenu: false, // We don't want to show it in the menu, we need it only for layout-plugins
        plugins: {
            ...plugins,
            // paragraphs: {
            //     ...paragraphs,
            //     paragraph,
            // },
            headings: {
                ...headings,
            },
        },
    };
});
