import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAttachmentsMap } from 'hooks/useAttachmentsMap';
import { Box } from '@mui/material';
import { BasicScrollSnapGallery } from 'components/LazyLightbox/BasicScrollSnapGallery';
import { LazyEditHouseDialog } from 'views/Rooms/Houses/LazyEditHouseDialog';
import styles from './HousingListItemDesktop.module.scss';
import { HousingListItemProps } from '../types/types';
import { HouseDto } from 'dtos/HouseDto';
import { ContactDetails } from '../HousingCommon/ContactDetails';
import { getLodgeHref } from '../lodgingHelpers';
import { HousingDescription } from '../../../views/LodgingSingle/Common/HousingDescription';
import { useAttachments } from 'hooks/useAttachments';
import { uniqueArray } from 'utils/uniqArray';

export function HousingListItemDesktop({ item }: HousingListItemProps) {
    const { attachments = [], roomAttachments, name, description, link, rooms, contactDetails } = item;
    const images = useAttachments(uniqueArray([...attachments, ...roomAttachments]));

    return (
        <Box component={Link} to={getLodgeHref(item.slug)} className={styles.houseItemDesktop} boxShadow={1}>
            <Box className={styles.gallery}>
                <BasicScrollSnapGallery
                    maxHeight={400}
                    disableKeyboardEvents
                    disableLightboxGallery
                    isMobile
                    images={images}
                />
            </Box>
            <Box className={styles.content}>
                <Box className={styles.header}>
                    <span>{name}</span>
                    <LazyEditHouseDialog house={item} />
                </Box>
                <Box className={styles.description}>
                    <HousingDescription description={description} truncate />
                    <ContactDetails contactDetails={contactDetails} />
                    {/* <DataRow label="Number of rooms" total={numRooms} />
                <DataRow label="Number of guests" total={numGuests} /> */}
                </Box>
            </Box>
        </Box>
    );
}
