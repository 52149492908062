import React, { useMemo } from 'react';
import { CtaButtonProps, CtaListData, CtaListProps } from './CtaListProps';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

const getIsInternal = (href: string) => href && href.startsWith('http');

function CtaButton({ variant, className, size, color, href, label, fontSize, isBanner }: CtaButtonProps) {
    const extraProps = useMemo(() => {
        const btnClassName = clsx(className, `btn-${color}`, `btn-${variant}`, isBanner && 'banner-cta');
        return !getIsInternal(href)
            ? {
                  component: Link,
                  to: href,
                  className: clsx(btnClassName, 'internal'),
              }
            : {
                  component: 'a',
                  href,
                  target: '_blank',
                  rel: 'noreferrer noopener',
                  className: clsx(btnClassName, 'external'),
              };
    }, [href, className]);

    return (
        <Button variant={variant} size={size} color={color} className={className} sx={{ fontSize }} {...extraProps}>
            {label}
        </Button>
    );
}

export const CtaListMarkup = ({ ctas = [], gap, justifyContent, sxProps = {}, isBanner }: CtaListData) => {
    return (
        <Box display="flex" sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap, justifyContent, ...sxProps }}>
            {ctas.map((cta) => {
                return (
                    <Box key={cta.cId || cta.id}>
                        <CtaButton {...cta} isBanner={isBanner} />
                    </Box>
                );
            })}
        </Box>
    );
};

export function CtaListComponent(props: CtaListProps) {
    const { data, isBanner } = props;
    return <CtaListMarkup {...data} isBanner={isBanner} />;
}
