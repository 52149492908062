import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import React, { lazy, Suspense, useState, useEffect } from 'react';

const PluginToolbar = lazy(() => import(/* webpackChunkName: "PluginToolbar" */ './PluginToolbar'));

export function LazyPluginToolbar() {
    const isAdmin = useLoggedInAdmin();
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        setShowEditor(isAdmin);
    }, [isAdmin]);

    if (!showEditor) {
        return null;
    }

    return (
        <Suspense fallback={() => null}>
            <PluginToolbar />
        </Suspense>
    );
}
