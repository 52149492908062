import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import { EditHouseDialogProps } from './housesTypes';

const EditHouseDialog = lazy(() => import('./EditHouseDialog'));

export function LazyEditHouseDialog(props: EditHouseDialogProps) {
    const isAdmin = useLoggedInAdmin();
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        setShowButton(isAdmin);
    }, [isAdmin]);

    if (!showButton) {
        return null;
    }

    return (
        <Suspense fallback={null}>
            <EditHouseDialog {...props} />
        </Suspense>
    );
}
