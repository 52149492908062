import React from 'react';
import { ActivitiesProps } from './ActivitiesProps';
import { ActivitiesList } from 'components/ActivitiesList/ActivitiesList';
import { Box } from '@mui/material';

export function ActivitiesComponent(props: ActivitiesProps) {
    const {
        readOnly,
        data: { variant, title },
    } = props;
    return (
        <Box data-plugin-type="Activities">
            <ActivitiesList title={title} readOnly={readOnly} variant={variant} />
        </Box>
    );
}
