import React from 'react';
import { HousingListProps } from '../types/types';
import { Box } from '@mui/material';
import { HotelListItem } from '../HotelListItem';

export function HousingListMobile({ items }: HousingListProps) {
    return (
        <Box display="flex" mt={-4} flexDirection="column" gap={1} height="100%">
            {items.map((item) => {
                return <HotelListItem hotel={item} key={item.id} />;
            })}
        </Box>
    );
}
