import { deepEquals } from "@react-page/editor";
import { MediaDto } from "dtos/MediaDto";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAttachments } from "store/selectors/attachments";

export function useAttachment(attachmentId: number): MediaDto | undefined {
    const attachments = useSelector(selectAttachments, deepEquals);
    return useMemo(() => {
        if(!attachmentId){
            return undefined;
        }
        return attachments.find(attachment => attachment.id === attachmentId);
    }, [attachments, attachmentId]);
}