import { Breakpoint, SxProps } from '@mui/material';
import { CellPluginComponentProps, DataTType } from '@react-page/editor';
import { CtaDto } from 'dtos/components/CtaDto';

export enum BannerLayouts {
    DEFAULT = 'DEFAULT',
    CTA_RIGHT = 'CTA_RIGHT',
}

export interface BannerData extends DataTType {
    className?: string;
    title?: string;
    description?: string;
    minHeightMobile?: number;
    minHeightDesktop?: number;
    imageId?: number;
    includeMeta?: boolean;
    imageOpacity?: number;
    ctas?: CtaDto[];
    sxProps?: SxProps;
    containerSize?: Breakpoint | false;
    containerSx?: SxProps;
    layout?: BannerLayouts;
    titleSx?: SxProps;
    descriptionSx?: SxProps;
}

export type BannerProps = CellPluginComponentProps<BannerData>;
