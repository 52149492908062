import React, { memo, useEffect, useRef } from "react";
import canUseDOM from "helpers/canUseDom";
import { createPortal } from "react-dom";

function shouldUpdate(prevProps: CSSPortalProps, nextProps: CSSPortalProps) {
  return prevProps.cssString === nextProps.cssString;
}

interface CSSPortalProps {
  id: string;
  cssString?: string;
}

export const CSSPortal = memo(function CSSPortal({
  id,
  cssString = "css",
}: CSSPortalProps) {
  const styleContainerRef = useRef<HTMLStyleElement | null>();

  useEffect(() => {
    const styleContainer = styleContainerRef.current;
    if (styleContainer) {
      const serverStyle = document.querySelector(
        `style[data-server-id="${id}"]`
      );
      if (serverStyle) {
        serverStyle.remove();
      }
    }
  }, [id]);

  if (!canUseDOM()) {
    return (
      <style data-widget-type="STYLES" data-server-id={id}>
        {cssString}
      </style>
    );
  }

  return createPortal(
    <style ref={styleContainerRef} data-widget-type="STYLE" id={id}>
      {cssString}
    </style>,
    document.head
  );
},
shouldUpdate);
