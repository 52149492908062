import { MediaDto } from "dtos/MediaDto";
import React, { lazy, Suspense } from "react";

export interface LightboxGalleryProps {
    images: MediaDto[];
    currentIndex?: number;
    onClose: () => void;
    isMobile?: boolean;
}

const LightboxGallery = lazy(() => import(/* webpackChunkName: "LightboxGallery" */ './LightboxGallery'));

const MobileLightboxGallery = lazy(
    () => import(/* webpackChunkName: "LightboxGalleryMobile" */ './LighboxGalleryMobile')
);

export function LazyLightboxGallery(props: LightboxGalleryProps) {
  if (props.currentIndex === undefined || props.currentIndex < 0) {
    return null;
  }

  return (
      <Suspense fallback={null}>
          {props.isMobile ? <MobileLightboxGallery {...props} /> : <LightboxGallery {...props} />}
      </Suspense>
  );
}
